import { Box, Spacer, Text } from '@chakra-ui/react';

import { format, getFormattedDate } from '~/hooks/use-format-date';

import { DEFAULT_CATALOG_PATH } from '../catalog/constants';

import { NavGroup, NavLink, SocialMediaLink, TitledNavGroup } from './types';

export const companyNavGroup: NavGroup = {
  title: 'Company',
  links: [
    {
      label: 'About Us',
      href: '/about-us',
    },
    {
      label: 'Why Udacity?',
      href: '/experience',
    },
    {
      label: 'Blog',
      href: 'https://udacity.com/blog',
      isAbsolute: true,
      isExternal: true,
    },
    {
      label: 'In the News',
      href: '/news',
    },
    {
      label: 'Jobs at Udacity',
      href: 'https://udacity.com/jobs',
      isAbsolute: true,
      isExternal: true,
    },
    {
      label: 'Become a Mentor',
      href: '/become-a-mentor',
    },
    {
      label: 'Partner with Udacity',
      href: '/partnerships',
    },
    // {
    //   label: 'Partners',
    //   href: '/partners',
    // },
    // {
    //   label: 'Become an Instructor',
    //   href: '/instructors',
    // },
  ],
  nestedNavGroups: [
    {
      title: 'Resources',
      links: [
        {
          label: 'Catalog',
          href: DEFAULT_CATALOG_PATH,
        },
        {
          label: 'Career Outcomes',
          href: '/career-outcomes',
        },
        {
          label: 'Help and FAQ',
          href: 'https://support.udacity.com/hc/en-us',
          isAbsolute: true,
        },
        {
          label: 'Scholarships',
          href: '/scholarships',
        },
      ],
    },
  ],
};

export const resourcesNavGroup: NavGroup = {
  title: 'Resources',
  links: [
    {
      label: 'Catalog',
      href: DEFAULT_CATALOG_PATH,
    },
    {
      label: 'Help and FAQ',
      href: 'https://support.udacity.com/hc/en-us',
      isAbsolute: true,
    },
    {
      label: 'Scholarships',
      href: '/scholarships',
    },
    // {
    //   label: 'Career Services',
    //   href: '/career-services',
    // },
    // {
    //   label: 'Student Services',
    //   href: '/student-services',
    // },
    // {
    //   label: 'Udacity Talks',
    //   href: '/udacity-talks',
    // },
    // {
    //   label: 'Scholarships',
    //   href: '/scholarships',
    // },
    // {
    //   label: 'What is AI?',
    //   href: '/what-is-ai',
    // },
    // {
    //   label: 'Udacity for Individuals',
    //   href: '/online-learning-for-individuals',
    // },
  ],
};

export const governmentNavGroup: NavGroup = {
  title: 'Government',
  links: [
    {
      label: 'Udacity for Government',
      href: '/government',
    },
  ],
};

export const supportNavGroup: NavGroup = {
  title: 'Support',
  links: [
    {
      label: 'Help and FAQ',
      href: '/help',
    },
    {
      label: 'Service Status',
      href: '/service-status',
    },
    {
      label: 'Tech Requirements',
      href: '/tech',
    },
    {
      label: 'Contact Us',
      href: '/contact',
    },
  ],
};

export const supportNavGroupWithGovernmentNavGroup: NavGroup = {
  ...supportNavGroup,
  nestedNavGroups: [governmentNavGroup],
};

export const enterpriseNavGroup: NavGroup = {
  title: 'Enterprise',
  links: [
    {
      label: 'Udacity for Enterprise',
      href: '/enterprise',
    },
    {
      label: 'Digital Transformation with Udacity',
      href: '/enterprise/digital-transformation',
    },
    {
      label: 'Udacity for Financial Services',
      href: '/enterprise/financial-services',
    },
    {
      label: 'Corporate Social Responsibility',
      href: '/enterprise/csr',
    },
    {
      label: 'Train-to-Hire',
      href: '/enterprise/train-to-hire',
    },
    {
      label: 'Enterprise Resource Center',
      href: '/resource-center',
    },
  ],
};

export const authenticatedNavigation: NavGroup[] = [companyNavGroup, supportNavGroup];

export const facebookLink: SocialMediaLink = {
  id: 'facebook',
  iconSrc: '/images/social/facebook.svg',
  href: 'https://www.facebook.com/Udacity',
  label: 'Facebook',
};

export const twitterLink: SocialMediaLink = {
  id: 'twitter',
  iconSrc: '/images/social/twitter.svg',
  href: 'https://twitter.com/udacity',
  label: 'Twitter',
};

export const linkedInLink: SocialMediaLink = {
  id: 'linkedin',
  iconSrc: '/images/social/linkedin.svg',
  href: 'https://www.linkedin.com/school/udacity/',
  label: 'LinkedIn',
};

export const instagramLink: SocialMediaLink = {
  id: 'instagram',
  iconSrc: '/images/social/instagram.svg',
  href: 'https://www.instagram.com/udacity/',
  label: 'Instagram',
};

export const socialMediaLinks: SocialMediaLink[] = [facebookLink, twitterLink, linkedInLink, instagramLink];

export const udacitySchoolsGroup: NavGroup = {
  title: 'Udacity Schools',
  links: [
    { label: 'School of Artificial Intelligence', href: '/school/artificial-intelligence' },
    { label: 'School of Autonomous Systems', href: '/school/autonomous-systems' },
    { label: 'School of Business', href: '/school/business' },
    { label: 'School of Cloud Computing', href: '/school/cloud-computing' },
    { label: 'School of Cybersecurity', href: '/school/cybersecurity' },
    { label: 'School of Data Science', href: '/school/data-science' },
    { label: 'School of Executive Leadership', href: '/school/executive-leadership' },
    { label: 'School of Product Management', href: '/school/product-management' },
    { label: 'School of Programming', href: '/school/programming' },
  ],
};

export const featuredProgramsGroup: NavGroup = {
  title: 'Featured Programs',
  links: [
    { label: 'Business Analytics', href: '/course/business-analytics-nanodegree--nd098' },
    { label: 'SQL', href: '/course/learn-sql--nd072' },
    { label: 'AWS Cloud Architect', href: '/course/aws-cloud-architect-nanodegree--nd063' },
    { label: 'Data Analyst', href: '/course/data-analyst-nanodegree--nd002' },
    { label: 'Intro to Programming', href: '/course/intro-to-programming-nanodegree--nd000' },
    { label: 'Digital Marketing', href: '/course/digital-marketing-nanodegree--nd018' },
    { label: 'Self Driving Car Engineer', href: '/course/self-driving-car-engineer-nanodegree--nd0013' },
  ],
};

export const onlyAtUdacityGroup: NavGroup = {
  title: 'Only at Udacity',
  links: [
    { label: 'Artificial Intelligence', href: '/course/ai-artificial-intelligence-nanodegree--nd898' },
    { label: 'Deep Learning', href: '/course/deep-learning-nanodegree--nd101' },
    { label: 'Digital Marketing', href: '/course/digital-marketing-nanodegree--nd018' },
    { label: 'Flying Car and Autonomous Flight Engineer', href: '/course/flying-car-nanodegree--nd787' },
    { label: 'Intro to Self-Driving Cars', href: '/course/intro-to-self-driving-cars--nd113' },
    { label: 'Machine Learning Engineer', href: '/course/aws-machine-learning-engineer-nanodegree--nd189' },
    { label: 'Robotics Software Engineer', href: '/course/robotics-software-engineer--nd209' },
  ],
};

export const featuredEnterpriseProgramsGroup: NavGroup = {
  title: 'Featured Enterprise Programs',
  links: [
    { label: 'Artificial Intelligence for Enterprise', href: '/enterprise/artificial-intelligence' },
    { label: 'Cybersecurity for Enterprise', href: '/enterprise/cybersecurity' },
    { label: 'Data Science for Enterprise', href: '/enterprise/data-science' },
  ],
};

export const collapsableNavigation: TitledNavGroup = {
  title: 'Featured Programs and Schools',
  navGroups: [udacitySchoolsGroup, featuredProgramsGroup, onlyAtUdacityGroup],
};

export const promotionalText = (
  <Text as='small' fontSize='xs'>
    <Box as='strong'>Udacity is the trusted market leader in talent transformation.</Box> We change lives, businesses,
    and nations through digital upskilling, developing the edge you need to conquer what&apos;s next.
    <Spacer h='16px' /> Udacity Nanodegree programs represent collaborations with our industry partners who help us
    develop our content and who hire many of our program graduates. Udacity is not an accredited university and
    doesn&apos;t confer traditional degrees.
  </Text>
);

const currentYear = getFormattedDate({ date: new Date(), format: format.year });
export const legalText = (
  <>
    {/* suppressHydrationWarning to suppress error logs for very rare edge cases (December 31st). Data is rehydrated on client in that case, so there's not an actual issue here. */}
    <Text as='small' fontSize='xs' suppressHydrationWarning={true}>
      © 2011-{currentYear} Udacity, Inc. &quot;Nanodegree&quot; is a registered trademark of Udacity. © 2011-
      {currentYear} Udacity, Inc.
      <br />
      We use cookies and other data collection technologies to provide the best experience for our customers.
    </Text>
  </>
);

export const legalNavigation: NavLink[] = [
  {
    label: 'Legal & Privacy',
    href: '/legal',
  },
  {
    label: 'Site Map',
    href: '/sitemap',
  },
];

export const unauthenticatedNavigation: NavGroup[] = [
  companyNavGroup,
  // resourcesNavGroup,
  udacitySchoolsGroup,
  featuredProgramsGroup,
  onlyAtUdacityGroup,
  // supportNavGroupWithGovernmentNavGroup,
  // enterpriseNavGroup,
];
