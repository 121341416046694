import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import debounce from 'lodash-es/debounce';
import snakeCase from 'lodash-es/snakeCase';
import { useTranslation } from 'react-i18next';

import {
  Actions,
  getAuthenticatedNavItems,
  getSettingsMenuItems,
  getUnauthenticatedNavItems,
  MobileMenu,
  Navigation,
  ReferrerData,
  Search,
  SettingsMenuItem,
  UdacityHeader,
} from '@udacity/udacity-header';
import auth from '@udacity/ureact-hoth';

import { trackEvent } from '~/features/analytics/track-event';
import { SEARCH_RESULTS_PATH } from '~/features/catalog/constants';
import { getEnterpriseCompanyData } from '~/features/catalog/controllers/get-enterprise-company-data';
import { deleteAuthedUserCookies } from '~/features/cookies/utils/delete-authed-user-cookies';
import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';
import { publicEnv } from '~/features/environment/public';
import { useIsConnectLearner } from '~/features/sessions/hooks/use-is-connect-learner';
import { useUser } from '~/features/user/hooks/use-user';
import { useUserExperience } from '~/features/user/hooks/use-user-experience';
import { useBaseUrl } from '~/hooks/use-base-url';

import { SearchResults } from './search-results';

export function PageHeader({ isSimpleMode }: { isSimpleMode?: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();
  const baseUrl = useBaseUrl();
  const { user, userProfileData, userCatalog, userType, jwt } = useUser();
  const userHasAllAccess = getActiveSubscriptionCookie();
  const { isConnectLearner } = useIsConnectLearner();
  const { userExperience } = useUserExperience();
  const [enterpriseCompanyLogoUrl, setEnterpriseCompanyLogoUrl] = useState<string | undefined>(undefined);
  const [enterpriseCompanyName, setEnterpriseCompanyName] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function fetchCompanyData() {
      const { name, logoUrl } = await getEnterpriseCompanyData(router.query.companySlug as string, jwt ?? '');

      if (logoUrl) {
        setEnterpriseCompanyLogoUrl(logoUrl);
      }
      if (name) {
        setEnterpriseCompanyName(name);
      }
    }
    router.query.companySlug && fetchCompanyData();
  }, [router.query.companySlug, jwt]);

  const envName = publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME;

  const { authEnvName, classroomEnvName } = useMemo(() => {
    if (envName === 'production') {
      return {
        authEnvName: 'production',
        classroomEnvName: 'production',
      } as const;
    } else if (envName === 'preview') {
      return {
        authEnvName: 'production',
        classroomEnvName: 'production',
      } as const;
    } else if (envName === 'staging') {
      return {
        authEnvName: 'staging',
        classroomEnvName: 'staging',
      } as const;
    } else if (envName === 'development') {
      return {
        authEnvName: 'staging',
        classroomEnvName: 'development',
      } as const;
    } else {
      return {
        authEnvName: 'staging',
        classroomEnvName: 'development',
      } as const;
    }
  }, [envName]);

  const activeRouteId = router.route === '/' || router.route === '/dashboard' ? 'home' : router.route.replace('/', '');

  const authenticatedNavItems = getAuthenticatedNavItems({
    baseUrl,
    activeId: activeRouteId,
    isUserSubscribed: Boolean(userHasAllAccess),
    dashboardUrl: '/dashboard',
    enterpriseCatalogUrl: userCatalog?.hasEmcCatalog ? userCatalog.catalogUrl ?? undefined : undefined,
    isConnectLearner,
    t,
  });

  const referrerData: ReferrerData | undefined = useMemo(() => {
    return user && userHasAllAccess
      ? {
          programId: '53db30e7-ba1c-4451-920d-13e1c89ba5d9',
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }
      : undefined;
  }, [user, userHasAllAccess]);

  const settingsMenuItems = useMemo(() => {
    const defaultSettingsMenuItems = getSettingsMenuItems(t, baseUrl, referrerData, userType);

    const focusedSettingsMenuItems = ['profile', 'notifications', 'logout'];

    if (userExperience?.hasFocused) {
      return defaultSettingsMenuItems.filter((item) => focusedSettingsMenuItems.includes(item.id));
    } else {
      return defaultSettingsMenuItems;
    }
  }, [baseUrl, referrerData, t, userExperience?.hasFocused, userType]);

  function handleSearchEnter(searchValue: string) {
    router.push(`${SEARCH_RESULTS_PATH}?searchValue=${searchValue}`);
  }

  return (
    <UdacityHeader
      actionsSlot={<Actions />}
      authCallbackUrl={baseUrl}
      authEnvName={authEnvName}
      authenticatedNavItems={authenticatedNavItems}
      classroomEnvName={classroomEnvName}
      enterpriseCompanyLogoUrl={enterpriseCompanyLogoUrl}
      enterpriseCompanyName={enterpriseCompanyName}
      isSimpleMode={isSimpleMode}
      isUserAuthenticated={Boolean(userProfileData)}
      linkComponent={Link}
      logoUrl={'/dashboard'}
      mobileMenuSlot={<MobileMenu />}
      navigationSlot={userExperience?.hasFocused ? undefined : <Navigation />}
      searchResultsSlot={<SearchResults />}
      searchSlot={userExperience?.hasFocused ? undefined : <Search />}
      settingsMenuItems={settingsMenuItems}
      unauthenticatedNavItems={getUnauthenticatedNavItems(baseUrl, activeRouteId)}
      userName={`${user?.firstName} ${user?.lastName}`}
      userProfileImageUrl={userProfileData?.photoUrl}
      onSearchChange={handleSearchChange}
      onSearchEnter={handleSearchEnter}
      onLogInClick={() =>
        trackEvent({
          name: 'Navbar Link Clicked',
          type: 'click',
          label: 'Navbar Link - Header Link Clicked - Log In',
        })
      }
      onNavItemClick={(navItem) => {
        if (navItem.groupTitle) {
          // Note: here the user has clicked on a link item within a group.
          trackEvent({
            type: 'click',
            name: 'Navbar Link Clicked',
            label: navItem.groupTitle,
            action: `navbar_${snakeCase(navItem.groupTitle)}_clicked`,
            value: navItem.label,
          });
        } else {
          trackEvent({
            type: 'click',
            name: 'Navbar Link Clicked',
            label: navItem.label,
            action: 'navbar_clicked',
          });
        }
      }}
      onSettingsMenuItemClick={(settingsMenuItem: SettingsMenuItem) => {
        if (settingsMenuItem.id === 'logout') {
          deleteAuthedUserCookies();

          auth.signOut({
            returnUrl: baseUrl,
          });
        }
        if (settingsMenuItem.id === 'refer-a-friend') {
          trackEvent({
            name: 'Profile Icon Clicked',
            type: 'click',
            label: 'Profile Icon Clicked - Refer a Friend',
          });
        }
      }}
      onSignUpClick={() =>
        trackEvent({
          name: 'Navbar Link Clicked',
          type: 'click',
          label: 'Navbar Link - Header Link Clicked - Sign Up',
        })
      }
    />
  );
}

const handleSearchChange = debounce(
  (term: string) =>
    trackEvent({
      name: 'Search Bar Clicked',
      type: 'search',
      action: 'key_words_searched',
      value: term,
      label: 'Search',
    }),
  500
);
